<template>
  <div class="auth-wrapper">
    <div class="row auth-inner">
      <div class="container">
        <div class="col-12">
          <b-img src="@/assets/images/logo.svg" />

          <h6 class="mt-4">Forgot your password?</h6>

          <b-form class="auth-login-form" @submit.prevent="sendEmail">
            <b-form-group>
              <label for="login-email">
                Email <span class="required">*</span>
              </label>
              <b-form-input
                id="login-email"
                v-model="userEmail"
                name="login-email"
              />
            </b-form-group>

            <div class="login-actions">
              <b-button type="submit" variant="success" block>
                Send instructions by email
              </b-button>
              <router-link class="forgot-password" to="/login">Log In</router-link>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormGroup, BFormInput, BForm, BButton, BImg } from 'bootstrap-vue'
// import Toast from '@/components/Toast.vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BImg,
  },
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      userEmail: '',
    }
  },
  methods: {
    sendEmail() {

    },
  },
}
</script>
